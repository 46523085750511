
body{
  /*
  background-color:  rgba(253,214,77,0.3)!important;
  background-color:  rgba(0,0,0,0.1)!important;
  */
}

table{
  /*
  background-color:  rgba(0,0,0,0.1)!important;
  */
  background-color:  rgba(255,255,255,1)!important;
  border-radius: 7px;
}

.card-img {
  height: 9rem;
  object-fit: cover;
} 

body .container-fluid{
  padding: 0px 30px 0px !important;
}

body .container{
  padding: 10px !important;
  border-radius: 7px;
  padding: 1rem;
  min-height: 50%;
}

.profile-icon{
  background: #FDD64D;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  color: #050505;
  border-radius: 50%;
  margin: 10px;
  padding: 8px 10px;
  overflow: hidden;
  text-transform: uppercase;
}

.highlight{
    background-color: yellow;    
}

.bg-base{
  background-color:  rgba(255,255,255,1)!important; 
}

#stop-user{
    /*background-color:  rgba(253,214,77,0.5)!important; yellow*/
    background-color:  rgba(0,0,0,0.2)!important;
    z-index: 10000;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    cursor: wait;
}

#stop-user>div{
    position: absolute;
    top: 40%; 
    left: 50%;
    font-weight: bold;
}

.pointer{
  cursor: pointer;
}

.border table, .border th, .border td {
  border: 1px solid black;
}

@media print {
  
  .print-container{
    overflow: hidden;
  }
  .body, .no-print {
    display: none;
  }

  .print {
    overflow: hidden;
    display: block !important;
  }

  body {
    overflow: hidden;
  }

}